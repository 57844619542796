import { z } from 'zod';
import { CampaignDisplay, ContentOrientation, TargetUser } from '../../../promotion.campaign.interfaces';
import { zodUtils } from '../../../../../shared/helpers/zodUtils';

const maxDescriptionChars = 60;
const maxTitleChars = 35;
const maxButtonTitleChars = 16;

const zodValidationSchema = z
    .object({
        id: zodUtils.numberOrNullSchema.optional().nullable(),
        campaignName: z.string().nonempty(),
        titleEn: z
            .string()
            .max(maxTitleChars)
            .nonempty()
            .refine((s) => s === s.toUpperCase(), 'Title must be uppercase'),
        titleDe: z
            .string()
            .max(maxTitleChars)
            .nonempty()
            .refine((s) => s === s.toUpperCase(), 'Title must be uppercase'),
        descriptionEn: z.string().max(maxDescriptionChars).nonempty(),
        descriptionDe: z.string().max(maxDescriptionChars).nonempty(),
        buttonTitleEn: z.string().max(maxButtonTitleChars).nonempty(),
        buttonTitleDe: z.string().max(maxButtonTitleChars).nonempty(),
        startDate: z.coerce.date(),
        endDate: z.coerce.date(),
        textColorHex: zodUtils.colorHexSchema,
        buttonColorHex: zodUtils.colorHexSchema,
        descriptionColorHex: zodUtils.colorHexSchema,
        closeIconColorHex: zodUtils.colorHexSchema,
        contentOrientation: z.nativeEnum(ContentOrientation),
        display: z.nativeEnum(CampaignDisplay),
        pictureEn: z
            .union([z.string().nonempty(), z.object({})])
            .refine((value) => value !== null && value !== undefined, {
                message: 'Field must be a string or an object.',
            }),
        pictureDe: z.union([z.string(), z.object({})]).refine((value) => value !== null && value !== undefined, {
            message: 'Field must be a string or an object.',
        }),
        videoEn: z.union([z.string(), z.any()]).optional().nullable(),
        videoDe: z.union([z.string(), z.any()]).optional().nullable(),
        regions: z
            .array(
                z.object({
                    regionName: z.enum(['DE_AT_STORE', 'US_STORE']),
                    shopUrl: z.string().nonempty(),
                }),
            )
            .min(1),
        targetUsers: z.array(z.nativeEnum(TargetUser)).min(1),
        segmentAudiences: z.array(z.number()),
        coupon: z
            .object({
                productId: z.number(),
                discount: z.object({
                    type: z.enum(['PERCENTAGE', 'FIXED']),
                    value: z.number().gt(0),
                }),
                expirationDate: z.coerce.date().min(new Date()),
            })
            .optional()
            .nullable(),
    })
    .refine(
        (data) => {
            const { videoDe, videoEn } = data;
            return !(videoDe !== null && videoEn === null) && !(videoDe === null && videoEn !== null);
        },
        {
            message: 'Either both "videoDe" and "videoEn" must be null or both must have values.',
        },
    );

const validationSchemas = {
    zodValidationSchema,
};

export default validationSchemas;
